import config from 'config';
import { http_client } from './api';

const getList = async (
  page = null,
  perPage = null,
  sort = null,
  orderBy = null,
  search = null,
  jenjang_id = null,
  mapel_id = null,
  type = null
) => {
  return http_client
    .get(
      config.apiUrl +
        'latihan?' +
        `${perPage ? 'perPage=' + perPage : ''}` +
        `${page ? '&page=' + page : ''}` +
        `${orderBy ? '&orderBy=' + orderBy : ''}` +
        `${sort ? '&sort=' + sort : ''}` +
        `${search ? '&search=' + search : ''}` +
        `${jenjang_id ? '&jenjang_id=' + jenjang_id : ''}` +
        `${mapel_id ? '&mapel_id=' + mapel_id : ''}` +
        `${type ? '&type=' + type : ''}`
    )
    .then((response) => {
      return response.data;
    });
};

const read = async (id) => {
  return http_client.get(config.apiUrl + 'latihan/' + id).then((response) => {
    return response.data;
  });
};

const createSession = async (id, data) => {
  return http_client.post(config.apiUrl + 'latihan/' + id, data).then((response) => {
    return response.data;
  });
};

const repeatSession = async (id, data) => {
  return http_client.patch(config.apiUrl + 'latihan/' + id, data).then((response) => {
    return response.data;
  });
};

const closeSession = async (data) => {
  return http_client.post(config.apiUrl + 'latihan/done', data).then((response) => {
    return response.data;
  });
};

const getHistory = async (latihanID) => {
  return http_client.get(config.apiUrl + 'latihan/' + latihanID + '/history').then((response) => {
    return response.data;
  });
};

const getRating = async (id) => {
  return http_client.get(config.apiUrl + 'latihan/' + id + '/rating').then((response) => {
    return response.data;
  });
};

const storeRating = async (id, data) => {
  return http_client.post(config.apiUrl + 'latihan/' + id + '/rating', data).then((response) => {
    return response.data;
  });
};

const LatihanService = {
  getList,
  read,
  createSession,
  repeatSession,
  closeSession,
  getHistory,
  getRating,
  storeRating
};

export default LatihanService;
