import config from 'config';
import { http_client } from './api';

const getList = async (page = null, perPage = null, sort = null, orderBy = null, search = null) => {
  return http_client
    .get(
      config.apiUrl +
        'matchword?' +
        `${perPage ? 'perPage=' + perPage : ''}` +
        `${page ? '&page=' + page : ''}` +
        `${orderBy ? '&orderBy=' + orderBy : ''}` +
        `${sort ? '&sort=' + sort : ''}` +
        `${search ? '&search=' + search : ''}`
    )
    .then((response) => {
      return response.data;
    });
};

const read = async (id) => {
  return http_client.get(config.apiUrl + 'matchword/' + id).then((response) => {
    return response.data;
  });
};

const createSession = async (id, data) => {
  return http_client.post(config.apiUrl + 'matchword/' + id, data).then((response) => {
    return response.data;
  });
};

const repeatSession = async (id, data) => {
  return http_client.patch(config.apiUrl + 'matchword/' + id, data).then((response) => {
    return response.data;
  });
};

const closeSession = async (data) => {
  return http_client.post(config.apiUrl + 'matchword/done', data).then((response) => {
    return response.data;
  });
};

const getHistory = async (id) => {
  return http_client.get(config.apiUrl + 'matchword/' + id + '/history').then((response) => {
    return response.data;
  });
};

const getSession = async (id, sessionID) => {
  return http_client.get(config.apiUrl + 'matchword/' + id + '/' + sessionID).then((response) => {
    return response.data;
  });
};

const getRating = async (id) => {
  return http_client.get(config.apiUrl + 'matchword/' + id + '/rating').then((response) => {
    return response.data;
  });
};

const storeRating = async (id, data) => {
  return http_client.post(config.apiUrl + 'matchword/' + id + '/rating', data).then((response) => {
    return response.data;
  });
};

const MatchWordService = {
  getList,
  read,
  createSession,
  repeatSession,
  closeSession,
  getSession,
  getHistory,
  getRating,
  storeRating
};

export default MatchWordService;
